.navBar_styledNavList{
    height: 43px;
    width: 433px;
    color: #7CB3BF;
    font-size: 1.6rem;
    display: flex;
    align-self: center;
    
    a{
        font-size: 1.6rem;
        box-sizing: border-box;
        text-decoration: none;
        color: #7CB3BF;
        font-weight: 400PX;
        display: block;
        padding: 12px 24px;
        line-height: 20px;
        align-self: center;
        border: 1px solid transparent;
        /* transition: all 490ms ease; */
        /* text-decoration: solid 1px  transparent; */
           
        
            &:hover{
                 color:#E9F7FF;
                 font-weight: 500;
                 border: 1px solid transparent;
                
                 /* border-image: radial-gradient(circle, rgba(51,44,85,0) 0%, rgba(238,133,205,1) 2%); */
                /* transition: all 490ms ease; */
    
                 border-image:radial-gradient(rgba(0,143,104,0) 75%, #27AAE1) 1;
                
      
    }
    }
    
}
.navBar_styledNavItem {
    /* width: 80px; */
    display: flex;
    align-items: center;
    /* padding-left: 24px;
    padding-right: 24px; */
    margin-right: 16px;

}
.navBar_styledNavItem:last-child{
    margin-right: 0px;
    
}
