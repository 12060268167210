
.editSatellite_wrapper{

    // height: 602px;
    width: 405px;

    background-color: #242122;
    border-radius: 16px;
    box-shadow: 0px 0px 40px rgba(191, 224, 255, 0.1);
    border: 1px solid #223540;
    box-sizing: border-box;
    color: #E9F7FF;
    margin: auto;
    margin-top: 117px;
    padding: 40px;
    position: relative;
   
    form{
        position: relative;
    }

    .editSatellite_headerWrapper{
        font-size: 2rem;
        color: #E9F7FF;
        font-weight: 400;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;

        svg{
            // display: inline-block;
            font-size: 2rem;
            color:#7CB3BF;
            transition: all 400ms ease;
            &:hover{
                color: #E9F7FF;
                transition: all 300ms ease;
                cursor: pointer;
            }
        }

    }
    .editSatellite_errorWrapper{
        font-size: 2rem;
        color:#E31863;
        text-align: center;
        font-family: 700;
    }
    .editSatellite_nameWrapper{
        line-height: 19.6px;
        font-size: 1.4rem;
        color: #E9F7FF;
        margin-bottom: 13px;
        span{
            color: #7CB3BF;
            display: inline-block;
            margin-right: 5px;
        }
    }
    .editSatellite_deleteButton{
        display: flex;
        margin: auto;
        margin-top: 28px;
        width: 145px;
        background-color: #242122;
        color: #E31863;
        border: none;
        outline: none;
        font-size: 1.4rem;
        font-family: Barlow;
        font-weight: 400;
        align-items: flex-ends;
        cursor: pointer;
        // border-bottom: 1px solid #e3186200;
        transition: all 300ms ease;
        &:hover{
            color: #C31556;
            // border-bottom: 1px solid #E31863;
            transition: all 300ms ease;
        }


        // position: relative;

        svg{
            display: inline-block;
            margin-right: 12px;
            // position: absolute;
            // top: 3px;
        }

    }
    .editSatellite_footerWrapper{
        margin: auto;
        margin-top: 38px;
        width: 206px;
        display: flex;
        justify-content: space-between;

        button{
            height: 41px;
            font-family: Barlow;
            font-size: 1.4rem;
            border-radius: 8px;
            cursor: pointer;
            display: inline-block;
            padding: 12px 16px 12px 16px


        }
        .editSatellite_cancelButton {
            color: #0E7CAA;
            border: 1px solid #0E7CAA;
            background-color: #242122;
            transition: all 300ms ease;

            &:hover{
                // color: #0CE2E2  ;
                // border: 1px solid #0CE2E2    ;
                transition: all 300ms ease;
                background-color: #223540;
            }

        }
        .editSatellite_submitButton{
            color: #E9F7FF;
            background: linear-gradient(285.98deg, #0E7CAA -57.07%, #0CE2E2 199.89%);
            border: none;
            transition: all 300ms ease;

            &:hover{
                background:linear-gradient(285.98deg, #0E7CAA -0.07%, #0CE2E2 130.89%);
                transition: all 300ms ease;
            }
        }
    }
    form input::-webkit-inner-spin-button, form input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }


    form input[type=number]{
        -moz-appearance: textfield;
    }
    form> .disabledTextField {
    input{
        color: #426168!important;
        cursor:not-allowed
    }
}

    form .disabledTextField{
        fieldset{
            outline: none;
            border-color:  #223540 !important;
            // cursor: not-allowed;
        }
    }
}
