.firstContWrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 325px;
    height: 390px;
    background-color:  #242122;
    color: #E9F7FF;
    font-size: 1.6rem;
    a{
        text-decoration: underline;
        display: inline-block;
        border-bottom: 1px solid #0E7CAA;
    }

}
.anchor_undelined{
    text-decoration: underline;
 
}
.addNewSatellite_header{
    font-family:  Barlow;
    font-weight: 400;
    font-size: 2rem;
    color: #E9F7FF;
    display: flex;
    justify-content: space-between;
    svg{
        color:#7CB3BF;
        font-size: 2.3rem;
    }

}
.tip {
    
    text-align: start;
    &:first-child{
        margin-top: 48px;
    }
}
.advise{
    margin-top: 16px;
    margin-bottom: 32px;
    // display: flex;
    border: 1px solid #20404A;
    border-radius: 8px;
    padding: 16px;
    display: inline-block;
    background: linear-gradient(90deg, #0CE2E2 0%, #0E7CAA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;   
    text-align: center;
    width: 325px;
    box-sizing: border-box;
    // text-align: start;
    a{
       text-decoration: underline;

    }
}
.additional{
    font-size: 13px;
    color: #7CB3BF;
    text-align: start;
    margin-top: 49px;
}
.buttonsWrapper{
    margin: auto;
    margin-top: 27px;
    text-align: center;
    width: 325px;
    display: flex;
    justify-content: center;
    button{
        height: 41px;
        padding:  12px, 16px, 12px, 16px;
        font-family:  Barlow;
        font-weight: 400;
        font-size: 1.4rem;
        border-radius: 8px;
        text-transform: none;
        &:first-child{
            background-color: #242122;
            border: 1px solid #0E7CAA ;
            color: #0E7CAA;
            display: block;
            margin-right: 12px;

        }
        &:nth-child(2){
            background: linear-gradient(285.98deg, #0E7CAA -57.07%, #0CE2E2 199.89%);
            color: #E9F7FF;
            transition: all 300ms ease;
            display: block;
            margin-left: 12px;
            &:hover{
                background:linear-gradient(285.98deg, #0E7CAA -0.07%, #0CE2E2 130.89%);
                transition: all 300ms ease;
            }
        }
    }
}


// .secondContWrap>div>div:last-child input, .secondContWrap>div>div:nth-child(5) input {
//     // background-color: #426168;
   
       
//             color: #426168!important;
//             cursor:not-allowed
        
//     }
    // .secondContWrap>div>div:last-child fieldset, .secondContWrap>div>div:nth-child(5) fieldset{
    //     border-color: #426168 !important;
    //     font-size: 16px;

    //     &:hover{
    //         border-color: #426168!important;
    //     }
       
           
    //             color: #426168!important;
    //             cursor:not-allowed
            
    //     }

    // .secondContWrap>div>div:first-child input::after {
        
        
    //         }
 
 .secondContWrap>div{
     position: relative;

     svg{
         position: absolute;
         top: 29px;
         left: 294px;
         font-size:  1.8rem;
         color: #7CB3BF;
         cursor: pointer !important;
         z-index: 1;
     }
 }

 .MuiPaper-root {
     background-color: rgba(0,0,0,0) !important;
 }
 .MuiPopover-paper{
    
        background-color: rgba(0,0,0,0) !important;
    
 }

 .details{
     font-size: 1.4rem;
     color: #E9F7FF;
     margin-bottom: 16px;
     text-align: start;
 }

 .thirdContWrap{
     position: relative;
    //  background-color: #E9F7FF;
     min-height: 327px;
     svg{
         position: absolute;
         top: 1px;
         left: 195px;
         cursor: pointer;
         color: #7CB3BF;
         font-size:  1.8rem;
     }
    //  margin-bottom: 98px;
 }

 .lastTab{
     display: flex;
     font-family: Barlow;
     font-weight: 400;
     width: 260px;
     margin: auto;
     margin-top: 110px;

 }
 .congratulationsWrapper{
     margin-left: 28px;
     margin-top: 15px;
     text-align: start;

     div:first-child{
         color: #E9F7FF;
         font-size: 2rem;
     }
     div:last-child{
        // display: inline-block;
        background: linear-gradient(90deg, #0CE2E2 -100%, #0E7CAA 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        font-size: 1.6rem;
    }
 }
 .closeIcon{
     cursor: pointer;
 }
 .satelliteAddedTab{

    button{
        height: 41px;
        padding:  12px, 16px, 12px, 16px;
        font-family:  Barlow;
        font-size: 1.4rem;
        border-radius: 8px;
        text-transform: none;
        background: linear-gradient(285.98deg, #0E7CAA -57.07%, #0CE2E2 199.89%);
        color: #E9F7FF;
        transition: all 300ms ease;
        margin-top: 100px;
        &:hover{
            background:linear-gradient(285.98deg, #0E7CAA -0.07%, #0CE2E2 130.89%);
            transition: all 300ms ease;
        }
 }
}
.modalWrapper{
    position: relative;
    // height: 20px;
    background-color: #E9F7FF;
}

.checkbox_wrapper{
    // position: absolute;
    width: 100px;
    margin-bottom: 54px;
    height: 36px;
    display: flex;
    align-items: center;
    width: 325px;
    top: 580px;
    border-radius: 8px;
    // background-color: #223540;
    color: #0EC0D3;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

    svg{
        cursor: pointer;
        display: inline-block;
        margin-left: 15px;
        margin-right: 11px;
        font-size: 18px;
    }

}

