.signIn{
    width: 100%;
    height: 100vh; 
    background-image:  url('../../images/background.png');
    background-size: cover;
}
.signInContainer{
    width: 325px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    top: 45px;
    left: 155px;
   
}
.logo{
    background-image: url('../../images/Logo.svg');
    width: 201px;
    height: 40px !important;
    background-size: cover;
    
}
.loginText{
    margin-top: 93px;
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: rgba(233, 247, 255, 1);
}
.loginUnderText{
    margin-top: 12px;
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: rgba(124, 179, 191, 1);
}
.forgot{
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(13, 185, 204, 1);
    text-align: right;
}
.inputForm{
 
}
.noAccount{
    margin-top: 24px;
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #7CB3BF;
}
.link{

    margin-left: 12px;
    font-family: Barlow;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: rgba(13, 185, 204, 1);
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px rgba(38, 38, 40, 1) inset !important;
 -webkit-text-fill-color: rgba(124, 179, 191, 1) !important;
}