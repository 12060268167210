.dashBoardLineChart_wrapper{
    width: 1360px;
    height: 319px;
    margin-top: 20px;
    border-radius: 8px;
    background-color: #262628;
    box-sizing: border-box;
    padding: 16px;
}
.dashBoardLineChart_headerWrapper{
    color: #7CB3BF;
    font-weight: 400;
    font-size: 1.4rem;
}
.dashBoardLineChart_lineChart_wrapper{
    width: 1330px;
    height: 240px;
    // background-color: aquamarine;
    margin-top: 27px;
}