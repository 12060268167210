
.tablePage_wrapper{
    background-color: blue;
    // max-height: 100%;
    // height: 40px;
    box-sizing: border-box;
    width: 1440px;
    padding-top: 52px;
    padding-left: 40px;
    padding-right: 40px;

    flex-grow: 1;

} 
.table_cellWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    min-height: 20px;
    max-height: 40px;
    line-height: 20px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
    // padding-left: 31px;
    
  
}

.table_flexHeaderWrapper{
    display: flex;
    width: 1360px;
    margin: auto;
    justify-content: space-between;
}
.table_headerWrapper{
    display: flex;
    box-sizing: border-box;
    color: #E9F7FF;
    height: 40px;
    font-size: 2rem;
    align-items:  flex-end;
    // margin-left: 40px;

    &>svg{
        cursor: pointer;
        color: #7CB3BF;
        transition: all 300ms ease;
        font-size: 2rem;
        margin-left: 12px;
        
        
        &:hover{
            color: #E9F7FF;
            transition: all 300ms ease;
        }
    }

    .table_headerButton_shawAll{
        width: 69px;
        color: #7CB3BF;
        font-size: 1.4rem;
        margin-top: 5px;
        margin-right: 16px; 
        position: relative; 
         margin-left: 20px;
        cursor: pointer;
        transition: all 300ms ease;
        &:hover{
            color: #E9F7FF;
            transition: all 300ms ease;
        }

        svg{
             position: absolute;
            top: -1px; 
            left: -25px; 
            font-size: 2rem;
            transform: rotate(90deg)
        }


    }
}
// position: absolute;
    // top: -8px;
    // right: 0px;

// .tableAllSatellites_exportButton
.tableAllSatellites_exportButton{
    position: relative;
    width: 101px;
    height : 41px;
    /* padding: 12px 16px; */
    font-family: Barlow;
    font-size: 1.4rem;
    font-weight: 400;
    background: linear-gradient(90deg, #0CE2E2 -100%, #0E7CAA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    outline: none;
    cursor: pointer;
    border: 1px solid #0E7CAA;
    border-radius: 8px;
    background-color: #242122;

    
    transition: all 300ms ease;

    &::before{
        content: '';
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        border-radius: 8px;
        transition: all 300ms ease;
        z-index: -1;
        // background-color: #20404A;
    }

    &:hover{
        // background: linear-gradient(90deg, #11a1a1 -100%, #0b5d80 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: #223540; 
        transition: all 300ms ease;
        &::before{
            content: '';
            position: absolute;
            background-color: #223540;
            transition: all 300ms ease;
        }
    }
}

.tableRows_wrapper{
    width: 1390px;
    // background-color: #7CB3BF;
    margin: auto;
    max-height: 678px;
    overflow-y: scroll;
    scrollbar-width: thin;
        scrollbar-color: rgb(73, 61, 61) ; 

    &::-webkit-scrollbar {
        width: 6px;
      } 
    &::-webkit-scrollbar-track {
        background: #242122;
        box-shadow: 0px 0px 4px 1px #242122 inset;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #20404A;
        border-radius: 5px; 
      }
}

.tablePage_noDataWrapper{
    text-align: center;
    margin-top: 65px;
    h2{
        text-align: center;
        color: #FFFFFF;
        font-size: 2.4rem;
        font-weight: 400;
        width: 300px;
         margin: auto;
         margin-top: 55px;
    }
}