.linechartWrapper{
    width: 1133px;
    margin: auto;
    // height: 445px;
    box-sizing: border-box;
    color: #7CB3BF;
    padding: 16px;
    padding-bottom: 25px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #262628;
    margin-bottom: 24px;

    // &:last-child{
    //     height: 6px;
    // }
}
.lineChart_tableHeader{
    
    font-family: Barlow;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #A3BBD0;
    padding-bottom: 20px;


}


.linechart_container{
    width: 1050px;
    height: 326px;
    margin: auto;
    // background-color: #2b2b64;
}
